<template>
  <div class="enterprise">
      <div class="login-center">
          <div class="login-form">
              <div class="enterprise-title">绑定企业账号</div>
              <div class="enterprise-center">
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
                    <el-form-item prop="phone">
                        <el-input v-model="ruleForm.phone" auto-complete="off" placeholder="请输入您的账号"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input type="password" v-model="ruleForm.password" auto-complete="off" placeholder="请输入您的密码"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom:0;">
                        <el-button ype="primary" @click="submitForm('ruleForm')" style="width:100%;" class="login_button">登 陆</el-button>
                    </el-form-item>
                </el-form>
                <div class="enterprise-bottom">
                    <p @click="goPassword">忘记密码？</p>
                    <p @click="goRegister">注册账号</p>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        // <!--验证手机号是否合法-->
        let checkTel = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码"));
            } else if (!this.checkMobile(value)) {
                callback(new Error("手机号码格式不正确"));
            } else {
                callback();
            }
        }
        // <!--验证密码-->
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                this.$refs.ruleForm.validateField("checkPass");
                }
                callback();
            }
        }
        return{
            ruleForm: {
                phone:"",
                password: "",
            },
            rules: {
                phone: [{ validator: checkTel, trigger: "change" }],
                password: [{ validator: validatePass, trigger: "change" }]
            }
        }
    },
    methods:{
        submitForm(formName) {
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    // onLogin({
                    //         phone:this.ruleForm.phone,
                    //         password:this.ruleForm.password
                    //     }).then(res=>{
                    //     if(res.data.code == 1){
                    //         this.$router.push("/");
                    //         console.log('登陆',res.data);
                    //         this.$message({
                    //         type:"success",
                    //         message:'登陆成功'
                    //         });
                    //         // 存储token
                    //         this.$store.commit('set_token',res.data.result)
                    //     }else{
                    //         this.$message(res.data.msg);
                    //     } 
                    //     })
                }
            })
        },

         // 验证手机号
        checkMobile(str) {
            let re = /^1\d{10}$/;
            if (re.test(str)) {
                return true;
            } else {
                return false;
            }
        },

        goRegister(){
            this.$router.push("/register")
        },

        goPassword(){
            this.$router.push("/password")
        }
    }
}
</script>

<style lang="scss" scoped>
.enterprise{
    .enterprise-title{
        width:100%;
        height:60px;
        background-color:#F8F8F8;
        color:#333333;
        font-size:16px;
        display:flex;
        align-items:center;
        justify-content: center;
    }
    .enterprise-center{
        padding: 40px 45px 0 45px;
        box-sizing: border-box;
        .enterprise-bottom{
            margin-top:10px;
            display:flex;
            align-items:center;
            justify-content: space-between;
            p{
                color:#123E90;
                font-size:16px;
                cursor: pointer;
            }
        }
    }
}
</style>